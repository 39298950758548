import React, { useContext } from "react";
import "./Portfolio.css";
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css";
import Sidebar from "../../img/sidebar.png";
import Ecommerce from "../../img/ecommerce.png";
import HOC from "../../img/hoc.png";
import MusicApp from "../../img/musicapp.png";
import { themeContext } from "../../Context";
const Portfolio = () => {
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;
  return (
    <div className="portfolio" id="portfolio">
      {/* heading */}
      <span style={{color: darkMode?'white': ''}}></span>
      <span>About Us</span>

      <h2 style={{fontSize:25 , fontFamily:"roboto"}}>About the Company</h2>
      <p>
As one of the fastest growing companies in India, Saral Software Services has grown to become one of the largest companies. Company establishment from 2020 and head office in Aurangabad and branch offices in Nagpur, Solapur, Kolhapur, Nashik.  In addition to offering various solutions and services to its customers, the company also offers a wide variety of products like Educational ERP College ERP, School ERP, Website development Solutions, Portal development & SEO etc.  Schools, Colleges, and Public libraries are successfully implementing this solution and providing students and employees with good facilities.
</p>

<h2 style={{fontSize:25 , fontFamily:"roboto"}}>Why Saral Library is important for institutions</h2>
<p>Saral-Lib is a cloud-based ideal library software that is accessible 24x7x365 from any device.  Knowledgeable librarians suggest the useful features which are relevant to a completely automated institutes / public library. There are many types of committees that place great importance on automated library systems. It is constantly being updated with new features that enhance our service to our customers. It is very user friendly and easy to teach new library staff and students. Customer Service is very prompt, clear, and friendly.</p>

<h2 style={{fontSize:25 , fontFamily:"roboto"}}>Admission Automation System</h2>
<p>College ERP is a popular Web-based ERP for any type of Institute. The Saral Admission Cloud-based solution is appropriate for any type of college or school. It is developed after extensive study of all the educational different Institutes. Institute requires for their Academic, office administration purpose for smooth line work and student/staff communication management.
Modules are ready for use at the institute and allow users to access the allotted systems. The management can obtain exclusive reports for society and institute development.  
In Saral-Admission Stakeholders : Students, Parents, Faculties, Administrative Staff etc.  
</p>

<h2 style={{fontSize:25 , fontFamily:"roboto"}}>Website Development</h2>
<p>Saral Software Services is provide world-class website design to customer because website is mirror of any type of organization. Every website is design on different theme we are bounded with our customer for service support our designed our provided website is very eye catchy, faster loading, with best service support. We develop fully dynamic, Semi dynamic website with in minimum time frame. We have served our services to numbers of clients, and they are fully satisfied with our work.</p>

      {/* slider */}
       {/* <Swiper
        spaceBetween={30}
        slidesPerView={3}
        grabCursor={true}
        className="portfolio-slider"
      >
        <SwiperSlide>
          <img src={Sidebar} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Ecommerce} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={MusicApp} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={HOC} alt="" />
        </SwiperSlide>
      </Swiper>  */}
    </div>
  );
};

export default Portfolio;
