import React from "react";
import "./Footer.css";
import Wave from "../../img/wave.png";
import Insta from "@iconscout/react-unicons/icons/uil-instagram";
import Facebook from "@iconscout/react-unicons/icons/uil-facebook";
import Gitub from "@iconscout/react-unicons/icons/uil-github";

const Footer = () => {
  return (
    <div className="footer">
      <img src={Wave} alt="" style={{ width: "100%" }} />
      <div className="f-content">
        <span style={{marginTop:30}} >saralsoftservices@gmail.com</span>
        <div className="f-icons">
          <Insta color="white" size={"3rem"} />
          <Facebook color="white" size={"3rem"} />
          <Gitub color="white" size={"3rem"} />
        </div>
        <span style={{marginTop:30}} >POWERED BY ACES MULTIMEDIA</span>
        {/* <span className="add">
        <br></br><br></br><br></br><br></br><br></br>
        <h2 style={{fontSize:20,fontFamily:"roboto" }}> Library Automation System</h2>
      <h2 style={{fontFamily:"roboto"}}>Account Automation System</h2>
      <h2 style={{fontFamily:"roboto"}}>Payroll Automation System</h2>
      <h2 style={{fontFamily:"roboto"}}>Grievance Automation System</h2>
      <h2 style={{fontFamily:"roboto"}}>Website Development</h2>
      <h2 style={{fontFamily:"roboto"}}> Admission Automation System</h2>
</span> */}
      {/* <span className="add" style={{fontSize:20 , color:"black"}}>
          Saral Software Services <br></br> <br></br>
Contact for Support & Sales  – 94036 55221, 8275895535 <br></br> <br></br>
Email- saralsoftservices@gmail.com <br></br> <br></br>
Website – www.saralss.com <br></br> <br></br>

          </span> */}
      
      </div>
   
    </div>
  );
};

export default Footer;
