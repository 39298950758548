import React from "react";
import Toggle from "../Toggle/Toggle";
import "./Navbar.css";
import ssssss from "../../img/ssssss.png";
import { Link } from "react-scroll";
const navbar = () => {
  return (
    <div className="n-wrapper" id="Navbar">
      {/* left */}
      <div className="n-left">
      
        <div className="n-name"><img src={ssssss} alt="" /></div>
        <Toggle />
      </div>
      {/* right */}
      <div className="n-right">
        <div className="n-list" >
          <ul style={{ listStyleType: "none"}}>
            <li>
              <Link activeClass="active" to="Intro" spy={true} smooth={true} style={{fontFamily:"roboto"}}>
                Home
              </Link>
            </li>
            <li>
              <Link to="services" spy={true} smooth={true} style={{fontFamily:"roboto"}}>
                Serivces
              </Link>
            </li>
            <li>
              <Link to="works" spy={true} smooth={true} style={{fontFamily:"roboto"}}>
                Experience
              </Link>
            </li>
            <li>
              <Link to="portfolio" spy={true} smooth={true} style={{fontFamily:"roboto"}}>
                About
              </Link>
            </li>
            <li>
              <Link to="testimonial" spy={true} smooth={true} style={{fontFamily:"roboto"}}>
                Testimonial
              </Link>
            </li>
          </ul>
        </div>
        <Link to="contact" spy={true} smooth={true} style={{fontFamily:"roboto"}}>
        <button className="button n-button" style={{fontFamily:"roboto"}}>Contact</button>
        </Link>
      </div>
    </div>
  );
};

export default navbar;
