import React, { useContext } from "react";
import "./Services.css";
import Card from "../Card/Card";
import HeartEmoji from "../../img/heartemoji.png";
import Library from "../../img/library.png";
import Glasses from "../../img/glasses.png";
import System from "../../img/system.png";

import Humble from "../../img/humble.png";
import Calendar from "../../img/calendar.png";

import { themeContext } from "../../Context";
import { motion } from "framer-motion";
import Content from './content.pdf';

const Services = () => {
  // context
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;

  // transition
  const transition = {
    duration: 1,
    type: "spring",
  };

  return (
    <div className="services" id="services">
      {/* left side */}
      <div className="awesome">
        {/* dark mode */}
        <span style={{ color: darkMode ? "white" : "" }}>Our Awesome</span>
        <span>services</span>
        
      
      <h2 style={{fontSize:20, color:"black" ,fontFamily:"roboto" }}> Library Automation System</h2>
      <h2 style={{fontFamily:"roboto"}}>Account Automation System</h2>
      <h2 style={{fontFamily:"roboto"}}>Payroll Automation System</h2>
      <h2 style={{fontFamily:"roboto"}}>Grievance Automation System</h2>
      <h2 style={{fontFamily:"roboto"}}>Website Development</h2>
      <h2 style={{fontFamily:"roboto"}}> Admission Automation System</h2>


        <a href={Content} download>

          <button className="button s-button" style={{fontFamily:"roboto"}}>Download Doc</button>
        </a>
        <div className="blur s-blur1" style={{ background: "#ABF1FF94" }}></div>
      </div>
      {/* right */}
      <div className="cards">
        {/* first card */}
        <motion.div
          initial={{ left: "25rem" }}
          whileInView={{ left: "18rem" }}
          transition={transition}
        >
          <Card
            emoji={Library}
            heading={"Library Automation System"}  
            detail={" Website Development"}            /// Figma, Sketch, Photoshop, Adobe Illustrator, Adobe xd ///
          />
        </motion.div>
        {/* second card */}
         <motion.div
          initial={{ left: "-6rem", top: "12rem" }}
          whileInView={{ left: "2rem" }}
          transition={transition}
        >
          <Card
            emoji={System}
            heading={"Admission Automation System"}   /// Developer ///
            detail={" Account Automation System "}          /// Html, Css, JavaScript, React, Nodejs, Express ///
          />
        </motion.div>
       {/* /* 3rd */ }
        <motion.div
          initial={{ top: "18rem", left: "25rem" }}
          whileInView={{ left: "18rem" }}
          transition={transition}
        >
          <Card
            emoji={Calendar}
            heading={"Payroll Automation System"}       /// UI/UX ///
            detail={"Grievance Automation System"}
            color="rgba(252, 166, 31, 0.45)"
          />
        </motion.div> 
        <div
          className="blur s-blur2"
          style={{ background: "var(--purple)" }}
        ></div>
      </div>
    </div>
  );
};

export default Services;
