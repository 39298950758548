import React, { useContext } from "react";
import "./Intro.css";
import Vector1 from "../../img/Vector1.png";
import Vector2 from "../../img/Vector2.png";
// import boy from "../../img/boy.png";
import li from "../../img/li.png";
import glassesimoji from "../../img/glassesimoji.png";
import Automation from "../../img/automation.png";

import thumbup from "../../img/thumbup.png";
import crown from "../../img/crown.png";
import FloatinDiv from "../FloatingDiv/FloatingDiv";
import Github from "../../img/github.png";
import LinkedIn from "../../img/linkedin.png";
import Instagram from "../../img/instagram.png";
import { themeContext } from "../../Context";
import { motion } from "framer-motion";
import { Link } from "react-scroll";
const Intro = () => {
  // Transition
  const transition = { duration: 2, type: "spring" };

  // context
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;

  return (
    <div className="Intro" id="Intro">
      {/* left name side */}
      <div className="i-left">
        <div className="i-name">
          {/* yahan change hy darkmode ka */}
          <span style={{ color: darkMode ? "white" : "" }}>Hy! Welcome To</span>
          <span>Saral Software Services</span>
          <span className="introp" style={{fontFamily:"roboto",  textAlignLast: "auto"}}>
            As one of the fastest growing companies in India, Saral Software Services has grown to become one of the largest companies. Company establishment from 2020 and head office in Aurangabad and branch offices in Nagpur, Solapur, Kolhapur, Nashik.  In addition to offering various solutions and services to its customers, the company also offers a wide variety of products like Educational ERP College ERP, School ERP, Website development Solutions, Portal development & SEO etc.  Schools, Colleges, and Public libraries 
            are successfully implementing this solution and providing students and employees with good facilities.
          </span>
        </div>
        <Link to="contact" smooth={true} spy={true}>
          <button className="button i-button" style={{fontFamily:"roboto"}}>Contact Us</button>
        </Link>
        {/* social icons */}
        <div className="i-icons">
          <img src={Github} alt="" />
          <img src={LinkedIn} alt="" />
          <img src={Instagram} alt="" />
        </div>
      </div>
      {/* right image side */}
      <div className="i-right">
        <img src={Vector1} alt="" />
        <img src={Vector2} alt="" />
        <img src={li} alt="" />
        {/* animation */}
        <motion.img 
          initial={{ left: "-36%" }}
          whileInView={{ left: "-14%" }}
          transition={transition}
          src={Automation}
          alt=""
        />

        <motion.div
          initial={{ top: "-4%", left: "74%" }}
          whileInView={{ left: "68%" }}
          transition={transition}
          className="floating-div"
        >
          <FloatinDiv img={crown} text1="Web" text2="Developement" />
        </motion.div>

        {/* animation */}
        <motion.div
          initial={{ left: "9rem", top: "7rem" }}
          whileInView={{ left: "0rem" }}
          transition={transition}
          className="floating-div"
        >
          {/* floatinDiv mein change hy dark mode ka */}
          <FloatinDiv img={thumbup} text1="Best Library" text2="ERP Solutions" />
        </motion.div>

        <motion.div
          initial={{ left: "15rem", top: "15rem" }}
          whileInView={{ left: "60%" }}
          transition={transition}
          className="floating-div"
        >
          {/* floatinDiv mein change hy dark mode ka */}
          <FloatinDiv img={thumbup} text1="Best Admission" text2="Automation System" />
        </motion.div>

        <div className="blur" style={{ background: "rgb(238 210 255)" }}></div>
        <div
          className="blur"
          style={{
            background: "#C1F5FF",
            top: "17rem",
            width: "21rem",
            height: "11rem",
            left: "-9rem",
          }}
        ></div>
      </div>
    </div>
  );
};

export default Intro;
