import React from "react";
import "./Testimonial.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

import { Pagination } from "swiper";
import "swiper/css/pagination";
import user from "../../img/user.png";
import user1 from "../../img/user1.jpg";
import user2 from "../../img/user2.jpeg";

const Testimonial = () => {
  const clients = [
    {
      img: user1,
      h2:"Shri Vijay Malekar",
      review:
      "This software is easy to use and work with i might say user friendly. Searching the book one wishes to read is pretty simple and quick. It has good customer service. The good thing about this software it is not dormant since new features keep being uploaded to the system.",
    },
    {
      img: user2,
      h2:"Mr. Dinkar Taywade",
      review:
        "Saral software services is the ideal Automation System for our small public library. It is very user friendly and easy to teach new employees. It is constantly being updated with new features that enhance our service to our customers. Customer Service is very prompt, clear, and friendly.",
    },
    {
      img: user,
      h2:"saloni",
      review:
        "Saralss is VERY user friendly- we've been able to do everything we need to do to keep great records for the library, and they regularly push out updates that give us features we didn't know that we needed. The company is always thinking ahead and finding ways to improve the product.",
    },
    {
      img: user,
      h2:"Akash",
      review:
        "We love how easy it is to use, and how it has freed up more time for our staff. Our cataloging process takes a third of the time it did before we had Saralss. Our patrons are now able to make reserves, renewals, etc. on their own from our online catalog. The staff training videos are invaluable, and their customer support is always quick and friendly. Couldn't dream of a better automation system!",
    },
  ];

  return (
    <div className="t-wrapper" id="testimonial">
      <div className="t-heading">
        <span>Clients always get </span>
        <span>Exceptional Work </span>
        <span>from Us...</span>
      <div className="blur t-blur1" style={{ background: "var(--purple)" }}></div>
      <div className="blur t-blur2" style={{ background: "skyblue" }}></div>

      </div>
      <Swiper
        // install Swiper modules
        modules={[Pagination]}
        slidesPerView={1}
        pagination={{ clickable: true }}
      >
        {clients.map((client, index) => {
          return (
            <SwiperSlide key={index}>
              <div className="testimonial">
                <img src={client.img} alt="" />
                <h2>{client.h2} </h2>
                <span style={{fontFamily:"roboto"}}>{client.review}</span>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default Testimonial;
